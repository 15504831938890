import React from 'react';
import PropTypes from 'prop-types';

import './post.css';

export const Post = ({post}) => {
    return (
        <article className='post'>
            <h2>{post.title.rendered}</h2>
            <div dangerouslySetInnerHTML={{ __html: post.content.rendered }}/>
        </article>
    );
};

Post.propTypes = {
    post: {
        title: {
            rendered: PropTypes.string
        },
        content: {
            rendered: PropTypes.string
        }
    }
};