import React from 'react';
import {
    QueryClient,
    QueryClientProvider
} from '@tanstack/react-query';

import { StandardTemplate } from './features/standard-template/standard-template';

import './app.css';
import { PostsContainer } from './features/posts/posts-container';

const queryClient = new QueryClient();

const App = () => {
    return (
        <StandardTemplate>
            <QueryClientProvider client={queryClient}>
                <PostsContainer></PostsContainer>
            </QueryClientProvider>
        </StandardTemplate>
    );
};

export default App;
