import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './app';

// if (process.env.NODE_ENV === 'development') {
//     const mockServer = require('../mocks/server');
//     mockServer({ environment: 'development' });
// }

const domNode = document.getElementById('root') as HTMLElement;
const root = createRoot(domNode);
root.render(<BrowserRouter><App /></BrowserRouter>);
