import React from 'react';
import PropTypes from 'prop-types';

import './standard-template.css';

export const StandardTemplate = ({children}) => {
    return (
        <div className='standard-template'>
            <div className='template-header'>

            </div>
            <div className='template-body'>
                {children}
            </div>
            <div className='template-footer'>

            </div>
        </div>
    );
};

StandardTemplate.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};
