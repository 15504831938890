import React from 'react';
import {
    useQuery,
} from '@tanstack/react-query';
import { Post } from './post-card';

const getPosts = async () => {
    try {
        const response = await fetch('https://xmi.mjf.mybluehost.me/wp-json/wp/v2/posts');

        if (!response.ok) {
            throw new Error(`Response status: ${response.status}`);
        }

        const json = await response.json();
        return json;
    } catch(error) {
        if (error instanceof Error) {
            console.error(error.message);
        }
    }
};

export const PostsContainer = () => {
    // const renderPosts = () => {
    //     return posts.map(post => (
    //         <h2 key={post.id}>{post.title.rendered}</h2>
    //     ));
    // };

    const { isLoading: isLoadingPosts, error: postsError, data: posts } = useQuery({
        queryKey: ['posts'],
        queryFn: () => getPosts(),
       // select: (posts) => posts.toString(),
        enabled: true
    });

    if (isLoadingPosts) return <p>...</p>;

    if (postsError) return <p>Error getting posts.</p>;

    return (
        <div className="posts-container">
            {posts.map(post => (
                <Post key={post.id} post={post}/>
            ))}
        </div>
    );
};